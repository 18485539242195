import validate from "/codebuild/output/src124722001/src/storefront/node_modules/nuxt/dist/pages/runtime/validate.js";
import _404_45global from "/codebuild/output/src124722001/src/storefront/middleware/404.global.js";
import manifest_45route_45rule from "/codebuild/output/src124722001/src/storefront/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _404_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/codebuild/output/src124722001/src/storefront/middleware/auth.js")
}