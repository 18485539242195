
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexVGQEnI7nJizpM23_mv8EYkdS7fREPgf_45YIadBBAXWpEMeta } from "/codebuild/output/src124722001/src/storefront/pages/index.vue?macro=true";
import { default as loginEPmwyEOh_AN3l0zGmYr1obiEZSHqGSCwShWlaSuDEc0Meta } from "/codebuild/output/src124722001/src/storefront/pages/login.vue?macro=true";
import { default as cookienyNV8X0NLKDVDLQcFVec_DRra1tVzvN_yV_oGRxH4ygMeta } from "/codebuild/output/src124722001/src/storefront/pages/cookie.vue?macro=true";
import { default as searchzVcB8xHzsiWErDfXQ74TN7h8cF96yr45iIO3Se3ufOsMeta } from "/codebuild/output/src124722001/src/storefront/pages/search.vue?macro=true";
import { default as my_45cartudYHm7Vg6k64uvC2sd7rKSe2OgujjnpvxKpLAJRXFTwMeta } from "/codebuild/output/src124722001/src/storefront/pages/my-cart.vue?macro=true";
import { default as on_45saleU8zkO0Q_zxNECQVrELP5V0y2bJY9X8HXr1rx_mJfw0kMeta } from "/codebuild/output/src124722001/src/storefront/pages/on-sale.vue?macro=true";
import { default as about_45usmIv_QVtqPlNq5DHIaoVo3xnzYuV2RJvS5cLLUri5lE4Meta } from "/codebuild/output/src124722001/src/storefront/pages/about-us.vue?macro=true";
import { default as book_45clubDuLIqIb3mXeqUQbdt1r2ej7yIUvAhnU6Hk2BkgpXXC4Meta } from "/codebuild/output/src124722001/src/storefront/pages/book-club.vue?macro=true";
import { default as contact_45usaqtR3Vo_452R2Cv_NMojttA1GnyPwZSfGZe6SX5tEae9MMeta } from "/codebuild/output/src124722001/src/storefront/pages/contact-us.vue?macro=true";
import { default as newspapersP7WeRELJNUNN9_45P7sNL8d8oI23K9B80bIPnIUUGO1usMeta } from "/codebuild/output/src124722001/src/storefront/pages/newspapers.vue?macro=true";
import { default as _91slug_93ZtiQMoGzTgFMaqkJAGqMf37EPAEoAlBSD3rVaD_45MMgkMeta } from "/codebuild/output/src124722001/src/storefront/pages/book/[slug].vue?macro=true";
import { default as indexwkd8A3nY5HWjrb2cC13DpVMVxweJcqNOgVmjO6zpvZgMeta } from "/codebuild/output/src124722001/src/storefront/pages/books/index.vue?macro=true";
import { default as barion_45startT7Y_45ZuHcpzo78KQA_FdiXNddjT57sRLtvdNzBWa7uj0Meta } from "/codebuild/output/src124722001/src/storefront/pages/barion-start.vue?macro=true";
import { default as paypal_45startzcLD__455_Zu7yE_45hTSiXsm3lhUKqzvn1NAm_RNynRldwMeta } from "/codebuild/output/src124722001/src/storefront/pages/paypal-start.vue?macro=true";
import { default as _91_46_46_46catchAll_93xyEzVv9RbPfEHbKnZRWrVqOd_45kPODInmApBucK4_45np4Meta } from "/codebuild/output/src124722001/src/storefront/pages/[...catchAll].vue?macro=true";
import { default as indexfhoEBzFXDdOHrqlHXC1_swfLX4U_ktWg9YTeq6y9Qk8Meta } from "/codebuild/output/src124722001/src/storefront/pages/checkout/index.vue?macro=true";
import { default as payment_45resultdtkdwgdjUNjWqbZtyQy7qeOIsurwdRa02nRBJ9hTUkoMeta } from "/codebuild/output/src124722001/src/storefront/pages/payment-result.vue?macro=true";
import { default as privacy_45policylVMKLmmCqSCn_45mTpq2zFL5N6yCfx4IHQj9pdiuErYjEMeta } from "/codebuild/output/src124722001/src/storefront/pages/privacy-policy.vue?macro=true";
import { default as index8TP0D4ryhqoQOz4QqvHDOq8HTRgXh9_QjKcP0xkQM5MMeta } from "/codebuild/output/src124722001/src/storefront/pages/my-orders/index.vue?macro=true";
import { default as newly_45publisheddj7yugozPaw2YleRForfgr2d9ad_vClWLlnrln8FLugMeta } from "/codebuild/output/src124722001/src/storefront/pages/newly-published.vue?macro=true";
import { default as _91slug_935aA_454SsJDxn6Wd_45NC5khd_45xHBN4WURmwBpwp45p30u0Meta } from "/codebuild/output/src124722001/src/storefront/pages/my-orders/[slug].vue?macro=true";
import { default as thankyoutVN9bpMTtZPqjggtV5a9T8vAHh5pa1zuzogZdGsBdH0Meta } from "/codebuild/output/src124722001/src/storefront/pages/checkout/thankyou.vue?macro=true";
import { default as upcoming_45releases3VwF6CvYw36gv_8kaw_lY5DuMs7Wi9TQQcyy4Voc61QMeta } from "/codebuild/output/src124722001/src/storefront/pages/upcoming-releases.vue?macro=true";
import { default as terms_45and_45conditionsXhltvjptMys_WHszYJbhR5IyAgihtEaUD1G6hXnRm00Meta } from "/codebuild/output/src124722001/src/storefront/pages/terms-and-conditions.vue?macro=true";
import { default as _91slug_9343LjfVIEzCWEhwANEJOn1eYWvvUz0WIpGLx9_45V94QzUMeta } from "/codebuild/output/src124722001/src/storefront/pages/other-language/[slug].vue?macro=true";
import { default as indexoJEnvKi9ha8qEbYL5x2FrhReG1cE8dowNbihbMzay38Meta } from "/codebuild/output/src124722001/src/storefront/pages/other-languages/index.vue?macro=true";
import { default as staff_45recommendations_45D5O_45yuYolwv4hYTkkz51WitnKA3Tiv64S1N9V9V_U4Meta } from "/codebuild/output/src124722001/src/storefront/pages/staff-recommendations.vue?macro=true";
import { default as _91slug_93vHR8DDQtoOmF9tj3ruXzUfRqapY7egCfNxZHz3zDbh0Meta } from "/codebuild/output/src124722001/src/storefront/pages/books-by-author/[slug].vue?macro=true";
import { default as indexX96SNTgFl0mSgJsUwqfrGcGS7ajF9W2ZXVBObPpH164Meta } from "/codebuild/output/src124722001/src/storefront/pages/books/[category]/index.vue?macro=true";
import { default as _91slug_93eZwYpmp8qLBOVW3_45RLobDjOGiACrf2h1sHtdqSI69ioMeta } from "/codebuild/output/src124722001/src/storefront/pages/menu-navigation/[slug].vue?macro=true";
import { default as bestsellers_45of_45the_45monthLY1ZV_i72JHr8TW8PApydcTGh4sfWhcXAlxiOK1u0E0Meta } from "/codebuild/output/src124722001/src/storefront/pages/bestsellers-of-the-month.vue?macro=true";
import { default as _91slug_931ul20g2GbPHjgAvy8C0Y0hG2sf2ZrfEROSDb622pMEEMeta } from "/codebuild/output/src124722001/src/storefront/pages/stationary-and-gift/[slug].vue?macro=true";
import { default as indexIrdTuPyCMhX8YRF0Ia_h5vt071iZl1hH_xuBVpvFiqEMeta } from "/codebuild/output/src124722001/src/storefront/pages/stationary-and-gifts/index.vue?macro=true";
import { default as indexWletNksC36ZfzDo_eo8R4uocmJBxYk0C3znnsFPM4RsMeta } from "/codebuild/output/src124722001/src/storefront/pages/other-languages/[category]/index.vue?macro=true";
import { default as purchase_45and_45shipping_45conditionsc16gy8QE8zPaE2guowqsdWsBr7JOMIMPQFhA2VKQwgkMeta } from "/codebuild/output/src124722001/src/storefront/pages/purchase-and-shipping-conditions.vue?macro=true";
import { default as indexreAzYSZ5M2Lw1zZX_45Erv4cqWgqteDG1rXknk2kAPBOwMeta } from "/codebuild/output/src124722001/src/storefront/pages/books/[category]/[subcategory]/index.vue?macro=true";
import { default as _91_91subcategory_93_93cq23Rw5DcxZb08C8u62CtEGy7k5bez_1V5FIpZE0QgsMeta } from "/codebuild/output/src124722001/src/storefront/pages/products/[[category]]/[[subcategory]].vue?macro=true";
import { default as indexJ7AL3AS_WyuXP_454cTZDrACpRKt8v2XI7QGGYhPzbVBsMeta } from "/codebuild/output/src124722001/src/storefront/pages/stationary-and-gifts/[category]/index.vue?macro=true";
import { default as indexqNNdD_1kmVjwlYb5Q5LjuvVXMXgza3RmpYXONewVosQMeta } from "/codebuild/output/src124722001/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue?macro=true";
import { default as indexwQhWslHeKPpKcuheX3FYBUdidmlWbacSSRhc60gYL3wMeta } from "/codebuild/output/src124722001/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue?macro=true";
export default [
  {
    name: "index___hu",
    path: "/hu",
    meta: indexVGQEnI7nJizpM23_mv8EYkdS7fREPgf_45YIadBBAXWpEMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexVGQEnI7nJizpM23_mv8EYkdS7fREPgf_45YIadBBAXWpEMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/index.vue")
  },
  {
    name: "login___hu",
    path: "/hu/bejelentkezes",
    meta: loginEPmwyEOh_AN3l0zGmYr1obiEZSHqGSCwShWlaSuDEc0Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/login",
    meta: loginEPmwyEOh_AN3l0zGmYr1obiEZSHqGSCwShWlaSuDEc0Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/login.vue")
  },
  {
    name: "cookie___hu",
    path: "/hu/suti",
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/cookie.vue")
  },
  {
    name: "cookie___en",
    path: "/cookie",
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/cookie.vue")
  },
  {
    name: "search___hu",
    path: "/hu/kereses",
    meta: searchzVcB8xHzsiWErDfXQ74TN7h8cF96yr45iIO3Se3ufOsMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/search.vue")
  },
  {
    name: "search___en",
    path: "/search",
    meta: searchzVcB8xHzsiWErDfXQ74TN7h8cF96yr45iIO3Se3ufOsMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/search.vue")
  },
  {
    name: "my-cart___hu",
    path: "/hu/kosaram",
    meta: my_45cartudYHm7Vg6k64uvC2sd7rKSe2OgujjnpvxKpLAJRXFTwMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/my-cart.vue")
  },
  {
    name: "my-cart___en",
    path: "/my-cart",
    meta: my_45cartudYHm7Vg6k64uvC2sd7rKSe2OgujjnpvxKpLAJRXFTwMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/my-cart.vue")
  },
  {
    name: "on-sale___hu",
    path: "/hu/akciok",
    meta: on_45saleU8zkO0Q_zxNECQVrELP5V0y2bJY9X8HXr1rx_mJfw0kMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/on-sale.vue")
  },
  {
    name: "on-sale___en",
    path: "/on-sale",
    meta: on_45saleU8zkO0Q_zxNECQVrELP5V0y2bJY9X8HXr1rx_mJfw0kMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/on-sale.vue")
  },
  {
    name: "about-us___hu",
    path: "/hu/rolunk",
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/about-us.vue")
  },
  {
    name: "about-us___en",
    path: "/about-us",
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/about-us.vue")
  },
  {
    name: "book-club___hu",
    path: "/hu/konyvklub",
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/book-club.vue")
  },
  {
    name: "book-club___en",
    path: "/book-club",
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/book-club.vue")
  },
  {
    name: "contact-us___hu",
    path: "/hu/kapcsolat",
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/contact-us.vue")
  },
  {
    name: "contact-us___en",
    path: "/contact-us",
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/contact-us.vue")
  },
  {
    name: "newspapers___hu",
    path: "/hu/ujsagok-es-magazinok",
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/newspapers.vue")
  },
  {
    name: "newspapers___en",
    path: "/newspapers-and-magazines",
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/newspapers.vue")
  },
  {
    name: "book-slug___hu",
    path: "/hu/konyv/:slug()",
    meta: _91slug_93ZtiQMoGzTgFMaqkJAGqMf37EPAEoAlBSD3rVaD_45MMgkMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/book/[slug].vue")
  },
  {
    name: "book-slug___en",
    path: "/book/:slug()",
    meta: _91slug_93ZtiQMoGzTgFMaqkJAGqMf37EPAEoAlBSD3rVaD_45MMgkMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/book/[slug].vue")
  },
  {
    name: "books___hu",
    path: "/hu/konyvek",
    meta: indexwkd8A3nY5HWjrb2cC13DpVMVxweJcqNOgVmjO6zpvZgMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/books/index.vue")
  },
  {
    name: "books___en",
    path: "/books",
    meta: indexwkd8A3nY5HWjrb2cC13DpVMVxweJcqNOgVmjO6zpvZgMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/books/index.vue")
  },
  {
    name: "barion-start___hu",
    path: "/hu/barion-fizetes",
    meta: barion_45startT7Y_45ZuHcpzo78KQA_FdiXNddjT57sRLtvdNzBWa7uj0Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/barion-start.vue")
  },
  {
    name: "barion-start___en",
    path: "/barion-start",
    meta: barion_45startT7Y_45ZuHcpzo78KQA_FdiXNddjT57sRLtvdNzBWa7uj0Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/barion-start.vue")
  },
  {
    name: "paypal-start___hu",
    path: "/hu/paypal-fizetes",
    meta: paypal_45startzcLD__455_Zu7yE_45hTSiXsm3lhUKqzvn1NAm_RNynRldwMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/paypal-start.vue")
  },
  {
    name: "paypal-start___en",
    path: "/paypal-start",
    meta: paypal_45startzcLD__455_Zu7yE_45hTSiXsm3lhUKqzvn1NAm_RNynRldwMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/paypal-start.vue")
  },
  {
    name: "catchAll___hu",
    path: "/hu/:catchAll(.*)*",
    meta: _91_46_46_46catchAll_93xyEzVv9RbPfEHbKnZRWrVqOd_45kPODInmApBucK4_45np4Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/[...catchAll].vue")
  },
  {
    name: "catchAll___en",
    path: "/:catchAll(.*)*",
    meta: _91_46_46_46catchAll_93xyEzVv9RbPfEHbKnZRWrVqOd_45kPODInmApBucK4_45np4Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/[...catchAll].vue")
  },
  {
    name: "checkout___hu",
    path: "/hu/megrendeles",
    meta: indexfhoEBzFXDdOHrqlHXC1_swfLX4U_ktWg9YTeq6y9Qk8Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/checkout/index.vue")
  },
  {
    name: "checkout___en",
    path: "/checkout",
    meta: indexfhoEBzFXDdOHrqlHXC1_swfLX4U_ktWg9YTeq6y9Qk8Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/checkout/index.vue")
  },
  {
    name: "payment-result___hu",
    path: "/hu/barion-eredmeny",
    meta: payment_45resultdtkdwgdjUNjWqbZtyQy7qeOIsurwdRa02nRBJ9hTUkoMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/payment-result.vue")
  },
  {
    name: "payment-result___en",
    path: "/barion-result",
    meta: payment_45resultdtkdwgdjUNjWqbZtyQy7qeOIsurwdRa02nRBJ9hTUkoMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/payment-result.vue")
  },
  {
    name: "privacy-policy___hu",
    path: "/hu/adatkezelesi-tajekoztato",
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/privacy-policy.vue")
  },
  {
    name: "privacy-policy___en",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/privacy-policy.vue")
  },
  {
    name: "my-orders___hu",
    path: "/hu/rendeleseim",
    meta: index8TP0D4ryhqoQOz4QqvHDOq8HTRgXh9_QjKcP0xkQM5MMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/my-orders/index.vue")
  },
  {
    name: "my-orders___en",
    path: "/my-orders",
    meta: index8TP0D4ryhqoQOz4QqvHDOq8HTRgXh9_QjKcP0xkQM5MMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/my-orders/index.vue")
  },
  {
    name: "newly-published___hu",
    path: "/hu/ujdonsagok",
    meta: newly_45publisheddj7yugozPaw2YleRForfgr2d9ad_vClWLlnrln8FLugMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/newly-published.vue")
  },
  {
    name: "newly-published___en",
    path: "/newly-published",
    meta: newly_45publisheddj7yugozPaw2YleRForfgr2d9ad_vClWLlnrln8FLugMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/newly-published.vue")
  },
  {
    name: "my-orders-slug___hu",
    path: "/hu/rendeleseim/:slug()",
    meta: _91slug_935aA_454SsJDxn6Wd_45NC5khd_45xHBN4WURmwBpwp45p30u0Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/my-orders/[slug].vue")
  },
  {
    name: "my-orders-slug___en",
    path: "/my-orders/:slug()",
    meta: _91slug_935aA_454SsJDxn6Wd_45NC5khd_45xHBN4WURmwBpwp45p30u0Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/my-orders/[slug].vue")
  },
  {
    name: "checkout-thankyou___hu",
    path: "/hu/megrendeles-koszonjuk",
    meta: thankyoutVN9bpMTtZPqjggtV5a9T8vAHh5pa1zuzogZdGsBdH0Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/checkout/thankyou.vue")
  },
  {
    name: "checkout-thankyou___en",
    path: "/checkout-thankyou",
    meta: thankyoutVN9bpMTtZPqjggtV5a9T8vAHh5pa1zuzogZdGsBdH0Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/checkout/thankyou.vue")
  },
  {
    name: "upcoming-releases___hu",
    path: "/hu/varhato-megjelenesek",
    meta: upcoming_45releases3VwF6CvYw36gv_8kaw_lY5DuMs7Wi9TQQcyy4Voc61QMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/upcoming-releases.vue")
  },
  {
    name: "upcoming-releases___en",
    path: "/upcoming-releases",
    meta: upcoming_45releases3VwF6CvYw36gv_8kaw_lY5DuMs7Wi9TQQcyy4Voc61QMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/upcoming-releases.vue")
  },
  {
    name: "terms-and-conditions___hu",
    path: "/hu/altalanos-szerzodesi-feltetelek",
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/terms-and-conditions.vue")
  },
  {
    name: "terms-and-conditions___en",
    path: "/terms-and-conditions",
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/terms-and-conditions.vue")
  },
  {
    name: "other-language-slug___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyv/:slug()",
    meta: _91slug_9343LjfVIEzCWEhwANEJOn1eYWvvUz0WIpGLx9_45V94QzUMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/other-language/[slug].vue")
  },
  {
    name: "other-language-slug___en",
    path: "/other-language/:slug()",
    meta: _91slug_9343LjfVIEzCWEhwANEJOn1eYWvvUz0WIpGLx9_45V94QzUMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/other-language/[slug].vue")
  },
  {
    name: "other-languages___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek",
    meta: indexoJEnvKi9ha8qEbYL5x2FrhReG1cE8dowNbihbMzay38Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/other-languages/index.vue")
  },
  {
    name: "other-languages___en",
    path: "/other-languages",
    meta: indexoJEnvKi9ha8qEbYL5x2FrhReG1cE8dowNbihbMzay38Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/other-languages/index.vue")
  },
  {
    name: "staff-recommendations___hu",
    path: "/hu/mi-ajanljuk",
    meta: staff_45recommendations_45D5O_45yuYolwv4hYTkkz51WitnKA3Tiv64S1N9V9V_U4Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/staff-recommendations.vue")
  },
  {
    name: "staff-recommendations___en",
    path: "/staff-recommendations",
    meta: staff_45recommendations_45D5O_45yuYolwv4hYTkkz51WitnKA3Tiv64S1N9V9V_U4Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/staff-recommendations.vue")
  },
  {
    name: "books-by-author-slug___hu",
    path: "/hu/szerzo-muvei/:slug()",
    meta: _91slug_93vHR8DDQtoOmF9tj3ruXzUfRqapY7egCfNxZHz3zDbh0Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/books-by-author/[slug].vue")
  },
  {
    name: "books-by-author-slug___en",
    path: "/books-by-author/:slug()",
    meta: _91slug_93vHR8DDQtoOmF9tj3ruXzUfRqapY7egCfNxZHz3zDbh0Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/books-by-author/[slug].vue")
  },
  {
    name: "books-category___hu",
    path: "/hu/konyvek/:category()",
    meta: indexX96SNTgFl0mSgJsUwqfrGcGS7ajF9W2ZXVBObPpH164Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/books/[category]/index.vue")
  },
  {
    name: "books-category___en",
    path: "/books/:category()",
    meta: indexX96SNTgFl0mSgJsUwqfrGcGS7ajF9W2ZXVBObPpH164Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/books/[category]/index.vue")
  },
  {
    name: "menu-navigation-slug___hu",
    path: "/hu/menu-navigacio/:slug()",
    meta: _91slug_93eZwYpmp8qLBOVW3_45RLobDjOGiACrf2h1sHtdqSI69ioMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/menu-navigation/[slug].vue")
  },
  {
    name: "menu-navigation-slug___en",
    path: "/menu-navigation/:slug()",
    meta: _91slug_93eZwYpmp8qLBOVW3_45RLobDjOGiACrf2h1sHtdqSI69ioMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/menu-navigation/[slug].vue")
  },
  {
    name: "bestsellers-of-the-month___hu",
    path: "/hu/a-honap-sikerkonyvei",
    meta: bestsellers_45of_45the_45monthLY1ZV_i72JHr8TW8PApydcTGh4sfWhcXAlxiOK1u0E0Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/bestsellers-of-the-month.vue")
  },
  {
    name: "bestsellers-of-the-month___en",
    path: "/bestsellers-of-the-month",
    meta: bestsellers_45of_45the_45monthLY1ZV_i72JHr8TW8PApydcTGh4sfWhcXAlxiOK1u0E0Meta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/bestsellers-of-the-month.vue")
  },
  {
    name: "stationary-and-gift-slug___hu",
    path: "/hu/notesz-es-ajandektargy/:slug()",
    meta: _91slug_931ul20g2GbPHjgAvy8C0Y0hG2sf2ZrfEROSDb622pMEEMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/stationary-and-gift/[slug].vue")
  },
  {
    name: "stationary-and-gift-slug___en",
    path: "/stationary-and-gift/:slug()",
    meta: _91slug_931ul20g2GbPHjgAvy8C0Y0hG2sf2ZrfEROSDb622pMEEMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/stationary-and-gift/[slug].vue")
  },
  {
    name: "stationary-and-gifts___hu",
    path: "/hu/noteszek-es-ajandektargyak",
    meta: indexIrdTuPyCMhX8YRF0Ia_h5vt071iZl1hH_xuBVpvFiqEMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/stationary-and-gifts/index.vue")
  },
  {
    name: "stationary-and-gifts___en",
    path: "/stationary-and-gifts",
    meta: indexIrdTuPyCMhX8YRF0Ia_h5vt071iZl1hH_xuBVpvFiqEMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/stationary-and-gifts/index.vue")
  },
  {
    name: "other-languages-category___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek/:category()",
    meta: indexWletNksC36ZfzDo_eo8R4uocmJBxYk0C3znnsFPM4RsMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/other-languages/[category]/index.vue")
  },
  {
    name: "other-languages-category___en",
    path: "/other-languages/:category()",
    meta: indexWletNksC36ZfzDo_eo8R4uocmJBxYk0C3znnsFPM4RsMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/other-languages/[category]/index.vue")
  },
  {
    name: "purchase-and-shipping-conditions___hu",
    path: "/hu/vasarlasi-es-szallitasi-feltetelek",
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/purchase-and-shipping-conditions.vue")
  },
  {
    name: "purchase-and-shipping-conditions___en",
    path: "/purchase-and-shipping-conditions",
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/purchase-and-shipping-conditions.vue")
  },
  {
    name: "books-category-subcategory___hu",
    path: "/hu/konyvek/:category()/:subcategory()",
    meta: indexreAzYSZ5M2Lw1zZX_45Erv4cqWgqteDG1rXknk2kAPBOwMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/books/[category]/[subcategory]/index.vue")
  },
  {
    name: "books-category-subcategory___en",
    path: "/books/:category()/:subcategory()",
    meta: indexreAzYSZ5M2Lw1zZX_45Erv4cqWgqteDG1rXknk2kAPBOwMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/books/[category]/[subcategory]/index.vue")
  },
  {
    name: "products-category-subcategory___hu",
    path: "/hu/product/:category?/:subcategory?",
    meta: _91_91subcategory_93_93cq23Rw5DcxZb08C8u62CtEGy7k5bez_1V5FIpZE0QgsMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/products/[[category]]/[[subcategory]].vue")
  },
  {
    name: "products-category-subcategory___en",
    path: "/products/:category?/:subcategory?",
    meta: _91_91subcategory_93_93cq23Rw5DcxZb08C8u62CtEGy7k5bez_1V5FIpZE0QgsMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/products/[[category]]/[[subcategory]].vue")
  },
  {
    name: "stationary-and-gifts-category___hu",
    path: "/hu/noteszek-es-ajandektargyak/:category()",
    meta: indexJ7AL3AS_WyuXP_454cTZDrACpRKt8v2XI7QGGYhPzbVBsMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/stationary-and-gifts/[category]/index.vue")
  },
  {
    name: "stationary-and-gifts-category___en",
    path: "/stationary-and-gifts/:category()",
    meta: indexJ7AL3AS_WyuXP_454cTZDrACpRKt8v2XI7QGGYhPzbVBsMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/stationary-and-gifts/[category]/index.vue")
  },
  {
    name: "other-languages-category-subcategory___hu",
    path: "/hu/egyeb-idegen-nyelvu-konyvek/:categorysubcategory?",
    meta: indexqNNdD_1kmVjwlYb5Q5LjuvVXMXgza3RmpYXONewVosQMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue")
  },
  {
    name: "other-languages-category-subcategory___en",
    path: "/other-languages/:category()/:subcategory()",
    meta: indexqNNdD_1kmVjwlYb5Q5LjuvVXMXgza3RmpYXONewVosQMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/other-languages/[category]/[subcategory]/index.vue")
  },
  {
    name: "stationary-and-gifts-category-subcategory___hu",
    path: "/hu/noteszek-es-ajandektargyak/:categorysubcategory?",
    meta: indexwQhWslHeKPpKcuheX3FYBUdidmlWbacSSRhc60gYL3wMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue")
  },
  {
    name: "stationary-and-gifts-category-subcategory___en",
    path: "/stationary-and-gifts/:category()/:subcategory()",
    meta: indexwQhWslHeKPpKcuheX3FYBUdidmlWbacSSRhc60gYL3wMeta || {},
    component: () => import("/codebuild/output/src124722001/src/storefront/pages/stationary-and-gifts/[category]/[subcategory]/index.vue")
  }
]